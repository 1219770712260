import React, { useEffect, useState } from "react";
import { Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

//Routes
//import InicioRoutes from "./inicio/Routes";

import "./styles.scss";
import Header from "../../../Containers/Header";
import Footer from "../../../Containers/Footer";
import SideMenu from "../../../Containers/SideMenu";
// import HeaderMenu from "../../../Containers/HeaderMenu";
import AnimateBG from "../../../components/AnimateBG";
import GeneralService from "../admin/Services/GeneralService";

import HallFame from "../admin/components/HallFame/HallFame";

/********Services***** */
import ThemeService from "../admin/Services/ThemeService";
import styled from "styled-components";
import toastr from "toastr";
import { useTranslation } from "react-i18next";

/******************Routes************** */
import { Route } from "react-router-dom";
import Inicio from "./inicio/Inicio";
import Metrics from "../../../Pages/Metrics";
import Tournament from "../../../Pages/Tournament";
import Bets from "../../../Pages/Bets";
import Quizzes from "../../../Pages/Quizzes";
import Map from "../../../Pages/Map";
import Quiz from "../../../Pages/Quiz";
import Mission from "../../../Pages/Mission";
import Metric from "../../../Pages/Metric";
import Badges from "../../../Pages/Badges";
import Ranking from "../../../Pages/Ranking";
import Prizes from "../../../Pages/Prizes";
import Avatar from "../../../Pages/Avatar";
import Challenges from "../../../Pages/Challenges";
import Missions from "../../../Pages/Missions";
import Socials from "../../../Pages/Social";
import LetterSoup from "../../../Pages/LetterSoup/LetterSoup";
import LetterSoupPlay from "../../../Pages/LetterSoup/LetterSoupPlay";
import Coaching from "../../../Pages/Coaching";

const AppWrapper = styled.div(
  ({ styles }) => `--warm-purple: #fa0050;
--main-blue: #100423;
--light-purple: #00d9fc;
--primary-green: #27a832;
--warm-red: #f84461;
--primary-btn-bg: #fa0050;
--tertiary-color: #0f0422;
--secondary-btn-bg:linear-gradient(180deg, #f947d3 0%, #f94a02 100%);
background-attachment: fixed;
--main-bg: ${
    styles.backgroundImage
      ? "("
      : "linear-gradient(45deg," +
        (styles && styles.backgroundColor1
          ? styles.backgroundColor1
          : " #fa0050") +
        "," +
        (styles && styles.backgroundColor2
          ? styles.backgroundColor2
          : " #fa0050")
  });
    
  background-image: url(${styles.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;

  span ,a ,p ,h1 ,h2 ,h3 ,h4 ,h5 ,label ,li, div  { color: ${
    styles && styles.textColor ? styles.textColor : "#000000"
  } !important };

  .bg-podium-number  { background-color: ${
    styles && styles.primaryColor ? styles.primaryColor : "#fa0050"
  } }; 
  
  path  { color: ${styles && styles.iconColor ? styles.iconColor : "black"}};

  .icon-new-dashboard path  { color: black  };
  
  .badge-div  { background-color: ${
    styles && styles.secondaryColor ? styles.secondaryColor : "#fa0050"
  } }; 

  .new-container-badge  { border: 3px solid ${
    styles && styles.secondaryColor ? styles.secondaryColor : "#fa0050"
  } 
    }; 

   .badge-points  { background-color: ${
     styles && styles.secondaryColor ? styles.secondaryColor : "#fa0050"
   }  };

  `
);

const AdminRoutes = (props) => {
  const name = props.sesionData.session.user.name;
  const lastName = props.sesionData.session.user.lastName;
  const userName = props.sesionData.session.user.username;
  const email = props.sesionData.session.user.email;
  const roles = props.sesionData.session.user.roles;
  const [preferences, setPreferences] = useState(false);

  const [styles, setStyles] = useState(false);
  const [menus, setMenus] = useState([]);
  const [options, setOptions] = useState([]);
  const { i18n } = useTranslation();

  const avatar = "/api" + props.sesionData.session.user.image;
  /* dev log */

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("pasa por aca asd");
    setLoading(true);

    GeneralFunctions();

    // eslint-disable-next-line
  }, []);

  const GeneralFunctions = async () => {
    if (window.localStorage.getItem("theme")) {
      setStyles(JSON.parse(window.localStorage.getItem("theme")));
      await getMenuLinks();
    } else {
      await getTheme();
    }
    await getPreferences();
    setLoading(false);
  };

  const getTheme = () => {
    ThemeService.getThemePublic()
      .then((response) => {
        if (response.data && response.data) {
          window.localStorage.setItem("theme", JSON.stringify(response.data));
          setStyles(response.data);
        }
        getMenuLinks();
      })
      .catch((error) => {
        toastr.error("Ha ocurrido un error al intentar obtener el diseño.");
      });
  };

  const getMenuLinks = () => {
    ThemeService.getMenuLinks()
      .then((response) => {
        setMenus(
          response.data
            .filter((data) => data.isShow === true)
            .map((m) => m.link)
        );
        setOptions(response.data.filter((data) => data.isShow === true));
      })
      .catch((error) => {
        toastr.error("Ha ocurrido un error al intentar obtener los links.");
      });
  };

  const getPreferences = () => {
    GeneralService.getPreferences()
      .then((response) => {
        i18n.changeLanguage(response.data.lang ? response.data.lang : "es");
        setPreferences(true);
      })
      .catch(() => {
        toastr.error("Hubo un error al obtener las preferencias.");
      });
  };

  return (
    <>
      {loading && (
        <Container>
          <Grid container spacing={1} className="justify-content-center">
            <SpinnerCircular size={90} height={570} />
          </Grid>
        </Container>
      )}
      {!loading && preferences && (
        <AppWrapper styles={styles}>
          <AnimateBG styles={styles} playerRoute={true}>
            <Header
              name={name}
              lastName={lastName}
              email={email}
              userName={userName}
              options={options}
              avatar={avatar}
              roles={roles}
            />

            {/* <HeaderMenu options={options} /> */}
            <SideMenu options={options} />

            <Switch>
              <Route key="default" exact path="/">
                <Redirect to="/panel" />
              </Route>
              <Route key="Dashboard" path="/panel">
                <Inicio />
              </Route>

              <Route key="quizzes" exact path="/quizzes">
                <Quizzes />
              </Route>

              <Route key="map" exact path="/map">
                <Map />
              </Route>

              <Route key="metrics" exact path="/metrics">
                <Metrics />
              </Route>
              
              <Route key="tournament" path="/tournament">
                <Tournament />
              </Route>
              
              <Route key="bets" path="/bets">
                <Bets />
              </Route>
              
              <Route key="badges" path="/badges">
                <Badges />
              </Route>
              
              <Route key="ranking" path="/ranking">
                <Ranking />
              </Route>
              
              <Route key="prizes" path="/prizes">
                <Prizes />
              </Route>
              
              <Route key="challenges" path="/challenges">
                <Challenges />
              </Route>
              
              <Route key="missions" exact path="/missions">
                <Missions />
              </Route>
              
              <Route key="hall-fame" path="/hall-fame">
                <HallFame />
              </Route>
              
              <Route key="social" path="/social">
                <Socials />
              </Route>
              

              <Route
                key="soup"
                path="/soup/play/:id/:userid"
                component={LetterSoupPlay}
              />
              <Route key="soup" path="/soup">
                <LetterSoup />
              </Route>

              <Route key="inicio-7" path="/quizzes/quiz">
                <Quiz />
              </Route>

              <Route key="inicio-8" path="/missions/mission">
                <Mission />
              </Route>

              <Route key="inicio-3" path="/metrics/metric">
                <Metric />
              </Route>

              <Route key="inicio-11" path="/avatar">
                <Avatar />
              </Route>

              <Route key="inicio-21" path="/coaching">
                <Coaching />
              </Route>

              <Redirect to="/panel" />
            </Switch>

            <Footer
              name={name}
              lastName={lastName}
              email={email}
              userName={userName}
              options={options}
              avatar={avatar}
              roles={roles}
            />
          </AnimateBG>
        </AppWrapper>
      )}
    </>
  );
};

const mapStateToProps = (state) => {

  return {
    sesionData: state.authReducer,
  };
};

export default connect(mapStateToProps)(AdminRoutes);
