import React, { useState } from 'react';
import './Wordsearch.css';
import './Letter.css';
import { create2dJsonArray } from './utils';

function Letter({ llave, estilo, chart, handleOnClick }) {
  return (
    <div key={llave} onClick={handleOnClick} className={`Letter ${estilo}`}>
      {chart}
    </div>
  );
}

function Wordsearch({ sopa, solution, handleWordFinded }) {
  const [selected, setSelected] = useState(null);
  const [puntoInicial, setpuntoInicial] = useState({ x: -1, y: -1 });
  //const [puntoFinal, setpuntoFinal] = useState({ x: -1, y: -1 });
  const [banderaColor, setBanderaColor] = useState(1);
  const [colorPosicion, setColorPosicion] = useState(
    create2dJsonArray(sopa.length, sopa.length, ''),
  );

  // retorna verdadero cuando enta en la misma fila o columna o diagonal
  const isRecto = (cI, cF) => {
    if ((cI.x === cF.x) || (cI.y === cF.y)) return true;
    if (Math.abs(cF.y - cI.y) === Math.abs(cI.x - cF.x)) return true;
    return false;
  }

  const direccionSentido = (p1, p2) => {

    let adjRow = Math.min(Math.max(p2.x - p1.x, -1), 1);
    let adjCol = Math.min(Math.max(p2.y - p1.y, -1), 1);
    //let forwards = adjRow >= 0 && adjCol >= 0;

    let dir = '';
    if (adjRow < 0) {
      dir += 'N';
    } else if (adjRow > 0) {
      dir += 'S';
    }
    if (adjCol < 0) {
      dir += 'W';
    } else if (adjCol > 0) {
      dir += 'E';
    }
    return dir;
  }


  /* A partir del puntoinicial y final segun al punto cardinal de movimiento
  optener la palabra indicada por el usuario desde el puzzle */
  const getPalabraInWords = (punto1, punto2) => {
    let auxPalabra = [];
    /* Si movimiento en fila columna o diagonal */
    if (isRecto(punto1, punto2) && (punto1.x >= 0) && (punto1.y >= 0) && (punto2.x >= 0) && (punto2.y >= 0)) {
      const auxPuzzle = sopa.slice();
      const puntoCardinal = direccionSentido(punto1, punto2);
      switch (puntoCardinal) {
        case 'N':
          for (let i = punto1.x; i >= punto2.x; i -= 1) {
            auxPalabra.push(auxPuzzle[i][punto1.y]);
          }
          break;

        case 'S':
          for (let i = punto1.x; i <= punto2.x; i += 1) {
            auxPalabra.push(auxPuzzle[i][punto1.y]);
          }
          break;

        case 'E':
          for (let j = punto1.y; j <= punto2.y; j += 1) {
            auxPalabra.push(auxPuzzle[punto1.x][j]);
          }
          break;

        case 'W':
          for (let j = punto1.y; j >= punto2.y; j -= 1) {
            auxPalabra.push(auxPuzzle[punto1.x][j]);
          }
          break;

        case 'NE':
          for (let i = punto1.x, j = punto1.y; (i >= punto2.x && j <= punto2.y); i -= 1, j += 1) {
            auxPalabra.push(auxPuzzle[i][j]);
          }
          break;
        case 'NW':
          for (let i = punto1.x, j = punto1.y; (i >= punto2.x && j >= punto2.y); i -= 1, j -= 1) {
            auxPalabra.push(auxPuzzle[i][j]);
          }
          break;

        case 'SE':
          for (let i = punto1.x, j = punto1.y; (i <= punto2.x && j <= punto2.y); i += 1, j += 1) {
            auxPalabra.push(auxPuzzle[i][j]);
          }
          break;

        case 'SW':
          for (let i = punto1.x, j = punto1.y; (i <= punto2.x && j >= punto2.y); i += 1, j -= 1) {
            auxPalabra.push(auxPuzzle[i][j]);
          }
          break;

        default:
          auxPalabra = null;
          break;
      }
    }
    return auxPalabra;
  }

  // comprueba si la palabra forma parte de la solucion
  const isSolution2 = (puntoInicial, puntoFinal) => {
    var ban = false;
      
    for (let i = 0; i < solution.length; i += 1) {
      let firstLetter = solution[i].firstLetter;
      let lastLetter = solution[i].lastLetter;
  
      if (puntoInicial.x == firstLetter[0] && puntoInicial.y == firstLetter[1] && puntoFinal.x == lastLetter[0] && puntoFinal.y == lastLetter[1]) {
        ban = true;
        break;
      } else if(puntoInicial.x == lastLetter[0] && puntoInicial.y == lastLetter[1] && puntoFinal.x == firstLetter[0] && puntoFinal.y == firstLetter[1]) {
        ban = true;
        break;
      }
    }
    return ban;
  }

  // comprueba si la palabra forma parte de la solucion
  const isSolution = (palabra) => {
    let aux_palabra = palabra;
    var ban = false;
    for (let i = 0; i < solution.length; i += 1) {
      if ((solution[i].word == aux_palabra) || (solution[i].word == aux_palabra.split('').reverse().join(''))) {
        ban = true;
        break;
      }
    }
    return ban;
  }

  const resetColorPosicionPalabra = () => {
    let aux = colorPosicion.slice();

    for (let i = 0; i < sopa.length; i += 1) {
      for (let j = 0; j < sopa[i].length; j += 1) {
        if (aux[i][j][1])
          aux[i][j] = [`color-0`, false];
      }
    }
    setColorPosicion(aux);
    setpuntoInicial({ x: -1, y: -1 });
    //setpuntoFinal({ x: -1, y: -1 });
  }

  const setColorPalabra = (punto1, punto2) => {
    let auxPalabra = [];
    // si movimiento en fila columna o diagonal
    if (isRecto(punto1, punto2) && (punto1.x >= 0) && (punto1.y >= 0) && (punto2.x >= 0) && (punto2.y >= 0)) {
      auxPalabra = colorPosicion.slice();
      const puntoCardinal = direccionSentido(punto1, punto2);
      switch (puntoCardinal) {
        case 'N':
          for (let i = punto1.x; i >= punto2.x; i -= 1) {
            auxPalabra[i][punto1.y] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;

        case 'S':
          for (let i = punto1.x; i <= punto2.x; i += 1) {
            auxPalabra[i][punto1.y] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;

        case 'E':
          for (let j = punto1.y; j <= punto2.y; j += 1) {
            auxPalabra[punto1.x][j] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;

        case 'W':
          for (let j = punto1.y; j >= punto2.y; j -= 1) {
            auxPalabra[punto1.x][j] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;

        case 'NE':
          for (let i = punto1.x, j = punto1.y; (i >= punto2.x && j <= punto2.y); i -= 1, j += 1) {
            auxPalabra[i][j] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;
        case 'NW':
          for (let i = punto1.x, j = punto1.y; (i >= punto2.x && j >= punto2.y); i -= 1, j -= 1) {
            auxPalabra[i][j] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;

        case 'SE':
          for (let i = punto1.x, j = punto1.y; (i <= punto2.x && j <= punto2.y); i += 1, j += 1) {
            auxPalabra[i][j] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;

        case 'SW':
          for (let i = punto1.x, j = punto1.y; (i <= punto2.x && j >= punto2.y); i += 1, j -= 1) {
            auxPalabra[i][j] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;

        default:
          auxPalabra = null;
          break;
      }
    }
    setColorPosicion(auxPalabra);
    setpuntoInicial({ x: -1, y: -1 });
    //setpuntoFinal({ x: -1, y: -1 });
  }

  const handleClick = (x, y, c) => {
    let auxPalabra = [];

    if (!selected) {
      setpuntoInicial({ x: x, y: y });
      //setpuntoFinal({ x: x, y: y });
      let auxC = colorPosicion.slice();
      auxC[x][y] = [`color-letter-soup-${banderaColor}`, true];
      setColorPosicion(auxC);
    } else {
      //setpuntoFinal({ x: x, y: y });
      if (isRecto(puntoInicial, { x: x, y: y })) {
        //si es recto y palabra correcta, cambiar el estilo de forma permanente a toda l a recta
        // obtener palabra entre punto inicial y final des puzzle
        auxPalabra = getPalabraInWords(puntoInicial, { x: x, y: y });
        auxPalabra = (auxPalabra) ? auxPalabra.join('') : '';
        
        // si la palabras forma parte de la respuesta
        //if (isSolution(auxPalabra)) {
        if (isSolution2(puntoInicial, { x: x, y: y })) {
          // poner el color de forma permanente entre punto 1 y punto 2
          setColorPalabra(puntoInicial, { x: x, y: y });
          setBanderaColor(prevC => prevC + 1);
          handleWordFinded(auxPalabra);

        } else {
          // sino   // resetear el color entre punto 1 y punto 2
          resetColorPosicionPalabra();
        }
        //resetColorPosicionPalabra();
      }
      else {
        // sino se le pone el estilo por defecto
        resetColorPosicionPalabra();
      }
    }
    setSelected((prevSlect) => !prevSlect);
  }

  /* eslint-disable react/no-array-index-key */
  const grid = (sopa && sopa.length) ? sopa.map((row, rowIndex) => (
    (row && row.length) && <div className="Wordsearch-row" key={"rowletter-"+rowIndex}>
      {row.map((char, colIndex) => (
        <Letter
          llave={`${rowIndex}-${colIndex}`}
          chart={char}
          estilo={colorPosicion[rowIndex][colIndex][0]}
          handleOnClick={() => handleClick(rowIndex, colIndex, char)}

        />
      ))}
    </div>
  )) : [];

  return (
    <div className="Wordsearch">
      {grid}
    </div>
  );
}
export default Wordsearch;