import { createActions, handleActions } from "redux-actions";
import { auth } from "./api/auth";
import store from "store2";

const TOKEN_LIFE = 2 * 60 * 60 * 1000; //two hours

const signup = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(postSignupInited());
      const response = await auth().postSignup(data);
      if (response.redirectUrl) {
        window.location.href =response.redirectUrl;
        return;
      }
      dispatch(
        setSession({
          ...response,
          expiresAt: new Date().getTime() + TOKEN_LIFE,
        })
      );
      localStorage.setItem(
        "sesion",
        JSON.stringify({
          ...response,
          expiresAt: new Date().getTime() + TOKEN_LIFE,
        })
      );
      //window.location.replace("/");
    } catch (error) {
      if (error && (error.status === "401" || error.status === 401)) {
        let errorMessage = {
          message: "Usuario y/o contraseña inválidas",
          status: 401,
        };
        dispatch(postSignupFailed(errorMessage));
      } else {
        let errorMessage = {
          message: "Ha habido un error, intenta más tarde",
          status: 500,
        };
        dispatch(postSignupFailed(errorMessage));
      }
    }
  };
};

const setSesion = (data) => {
  return async (dispatch, getState) => {
    const response = data;
    if (response !== null && response !== undefined) {
      if (response.token == null || !response.token) {
        dispatch(postLoginFailed(new Error("SESSION_ERROR")));
      } else dispatch(setSession(response));
    }
  };
};

const loginIntegracion = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(postLoginInited());

      const response = await auth().getLoginExternal(data);
      if (response.redirecturl == null || !response.redirecturl) {
        dispatch(postLoginFailed(new Error("SESSION_ERROR")));
      }
      window.location.href = response.redirecturl; //redirect to auto login
    } catch (error) {
      if (error && (error.status === "401" || error.status === 401)) {
        let errorMessage = { ...error };
        dispatch(postLoginFailed(errorMessage));
      } else {
        let errorMessage = { ...error };

        dispatch(postLoginFailed(errorMessage));
      }
    }
  };
};

const login = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(postLoginInited());
      const response = await auth().postLogin(data);

      if (response.token == null || !response.token) {
        dispatch(postLoginFailed(new Error("SESSION_ERROR")));
        return {
          error: "No se pudo loguear.",
        };
      } else {
        if (
          (response.user && response.user.isAcceptedConditions) ||
          "WEB" !== response.souceType
        ) {
          dispatch(
            setSession({
              ...response,
              expiresAt: new Date().getTime() + TOKEN_LIFE,
              isLoading: false,
            })
          );

          localStorage.setItem(
            "sesion",
            JSON.stringify({
              ...response,
              expiresAt: new Date().getTime() + TOKEN_LIFE,
            })
          );
          return "OK";
        } else {
          dispatch(
            setSession({
              isLoading: false,
            })
          );
          return {
            ...response,
            expiresAt: new Date().getTime() + TOKEN_LIFE,
          };
        }
      }
    } catch (error) {
      if (error && (error.status === "401" || error.status === 401)) {
        let errorMessage = { ...error };

        dispatch(postLoginFailed(errorMessage));
        return error;
      } else {
        let errorMessage = { ...error };
        dispatch(postLoginFailed(errorMessage));
        return error;
      }
    }
  };
};

const loginVerifiedUser = (data) => {
  return async (dispatch, getState) => {
    try {
      await auth().verifyUserTerms(data.token);
      dispatch(
        setSession({
          ...data,
          expiresAt: new Date().getTime() + TOKEN_LIFE,
        })
      );

      localStorage.setItem(
        "sesion",
        JSON.stringify({
          ...data,
          expiresAt: new Date().getTime() + TOKEN_LIFE,
        })
      );
      //window.location.replace("/");
    } catch (error) {
      dispatch(postLoginFailed(error));
      return error;
    }
  };
};

const refreshToken = (token) => {
  return async (dispatch, getState) => {
    try {
      let accessToken = store("token");
      if (token) {
        accessToken = token;
      }

      const response = await auth(accessToken).postRefreshToken();
      dispatch(
        setSession({
          ...response,
          expiresAt: new Date().getTime() + TOKEN_LIFE,
        })
      );
      localStorage.setItem(
        "sesion",
        JSON.stringify({
          ...response,
          expiresAt: new Date().getTime() + TOKEN_LIFE,
        })
      );
      //window.location.replace("/");
    } catch (error) {
      try {
        dispatch(logout());
      } catch {}
    }
  };
};

const {
  auth: {
    postSignupInited,
    postSignupFailed,
    cleanError,
    postLoginInited,
    postLoginFailed,
    setSession,
    logout,
    changeRole,
  },
} = createActions({
  auth: {
    postSignupInited: () => ({}),
    postSignupFailed: (error) => ({ error }),
    cleanError: () => ({}),
    postLoginInited: () => ({}),
    postLoginFailed: (error) => ({ error }),
    setSession: (response) => ({ ...response }),
    logout: () => ({}),
    changeRole: (data) => ({ data }),
  },
});

const initialState = {
  session: null,
  isLoading: false,
  error: null,
};
const authReducer = handleActions(
  {
    [logout]: (state, action) => {
      const isShowroom = localStorage.getItem("showroomMode");
      const isGamificaMode = localStorage.getItem("gamificaMode");

      store.clearAll();
      if (isShowroom) {
        window.location.replace("/showroom");
      } else if(isGamificaMode) {
        window.location.replace("/academia");
      } else {
        window.location.replace("/");
      }
      return { ...initialState };
    },
    [setSession]: (state, action) => {
      let session = { ...action.payload };
      if (!session || !session.user) {
        return;
      }
      store("token", session.token);
      //session.permisos = Object.keys(session.permisos);
      //const roles = getRoles(session.permisos);
      const loggedAs =
        session.user.role === 3 ? USER_TYPES.user : USER_TYPES.admin;
      //console.log(roles);
      store("loggedAs", loggedAs);
      return {
        ...state,
        isLoading: false,
        loggedAs: loggedAs,
        //roles: roles,
        session: session,
      };
    },
    [changeRole]: (state, action) => {
      const newLoggedAs =
        state.loggedAs === USER_TYPES.admin
          ? USER_TYPES.user
          : USER_TYPES.admin;
      return {
        session: {
          user: action.payload.data.user,
          company: action.payload.data.company,

          group: action.payload.data.group,
          feeling: action.payload.data.feeling,

          token: action.payload.data.token,
          expiresAt: action.payload.data.expiresAt,
        },
        loggedAs: newLoggedAs,
      };
    },
    [postSignupInited]: (state, action) => {
      return { ...initialState, isLoading: true };
    },

    [postSignupFailed]: (state, action) => {
      return {
        ...initialState,
        isLoading: false,
        error: action.payload,
      };
    },
    [postLoginInited]: (state, action) => {
      return { ...initialState, isLoading: true };
    },

    [postLoginFailed]: (state, action) => {
      return {
        ...initialState,
        error: action.payload,
      };
    },

    [cleanError]: (state, action) => {
      return {
        ...initialState,
      };
    },
  },
  initialState
);

const getRoles = (permisos) => {
  let roles = [];
  if (permisos.some((r) => ADMIN_PERMS.indexOf(r) === 0))
    roles.push(USER_TYPES.admin);
  if (permisos.some((r) => USER_PERMS.indexOf(r) === 0))
    roles.push(USER_TYPES.user);

  return roles;
};
//TODO: make perms iterable,searchable and comparable.
const USER_PERMS = ["permisoaplicacion"];
const ADMIN_PERMS = [
  "permisousuarios",
  "permisoconocimiento",
  "permisoperformance",
  "permisoapuestas",
  "permisofixture",
  "permisopremios",
  "permisodisenio",
  "permisoinsignas",
  "permisoreportes",
];
export const USER_TYPES = { admin: "ADMIN", user: "USER" };
export default authReducer;
export {
  logout,
  login,
  loginVerifiedUser,
  signup,
  refreshToken,
  changeRole,
  cleanError,
  setSesion,
  loginIntegracion,
};
