import React, { useState, useEffect, useRef } from "react";
import { Button } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import ButtonM from "../../components/Button";
import CropImageDialog from "../../components/app/admin/common/Dialogs/CropImageDialog";
import ShowImageDialog from "../../components/app/admin/common/Dialogs/ShowImage";
import { calculateSize } from "../../libs/helpers";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toastr from "toastr";
import JSConfetti from "js-confetti";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import newIconStar from "../../assets/img/newIconStar.png" 
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import ForumIcon from "@material-ui/icons/Forum";
import Input from "@material-ui/core/Input";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TextField from "@material-ui/core/TextField";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MissionServices from "../../Services/Mission/Player";
import FileService from "../../components/app/admin/Services/FileService";
import AssistantIcon from "@material-ui/icons/Assistant";
import Avatar from "../../components/Avatar";
import environment from "../../libs/environment";
import avatar from "../../assets/img/user.svg";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import SendIcon from "@material-ui/icons/Send";
import useUserSesion from "../../hooks/useUserSesion";
import novedad_logo from "../../assets/img/novedad-logo.png";
import Loader from "../../components/Loader";
import SearchIcon from '@material-ui/icons/Search';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.scss";

const MAX_FILE_SIZE = 1000000;

const Mission = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const URLinterface = new URLSearchParams(location.search);
  const id = URLinterface.get("id");

  const jsConfetti = new JSConfetti();

  const [openCropImage, setOpenCropImage] = useState(false);
  const [openImageCrop, setOpenImageCrop] = useState(null);
  const [comment, setComment] = useState("");
  const [commentForPlayer, setCommentForPlayer] = useState("");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [isSendingCommentForPlayer, setIsSendingCommentForPlayer] = useState(false);
  const [dragging, setDragging] = useState(null);
  const [otherPublic, setOtherPuclic] = useState([]);
  const [mission, setMission] = useState([]);
  const [user, setUser] = useState([]);
  const [missionCompleted, setMissionCompleted] = useState(false);
  const [sesion] = useUserSesion();
  const [urlImage, setUrlImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [videoModal, setVideoModal] = useState(null);
  const [videoFromYoutube, setVideoFromYoutube] = useState(null);
  const [imageModal, setImageModal] = useState(null);
  const [expirationDate, setexpirationDate] = useState(null);
  const [loadingComments, setLoadingComments] = useState(false);
  const [noMoreComments, setNoMoreComments] = useState(false);
  const [indexComments, setIndexComments] = useState(0);
  const [userSearch, setUserSearch] = useState("");
  const youtubeLink = "";
  const currentDate = new Date();
  const divRef = useRef(null);

  useEffect(() => {
    MissionServices.getMission(id, true, 6, 0)
      .then((resp) => {
        setMission(resp.data.content[0].mission);
        setexpirationDate(new Date(resp.data.content[0].mission.expirationDate));
        setUser(resp.data.content[0].user);
        setOtherPuclic(resp.data.content[0].otherPuclic);
      })
      .catch(() => {});
    return;
  }, []);

  useEffect(() => {
    if (otherPublic.length > 0 && sesion != null && missionCompleted != true) {
      setMissionCompleted(
        otherPublic.find((elem) => elem.user.id == sesion.user.id) != undefined
      );
    }
  }, [sesion, otherPublic]);

  const handleChangeComment = (event) => {
    setCommentForPlayer(event.target.value);
  };

  const handleFile = (event) => {
    let fileSelected = event.target.files[0];

    if (fileSelected.type.split("/")[0] + "" === "image") {
      setOpenCropImage(true);
      setOpenImageCrop(event.target.files[0]);
    } else {
      setFile(fileSelected);
    }
  };

  const handleKeySend = (evt, MISSION_ID, index) => {
    if (evt.key === "Enter") handleSendComment(MISSION_ID, index);
  };

  const handleSendComment = async (MISSION_ID, index) => {
    if (esValidoPorFechaDeVencimiento() == false) {
      return;
    }

    if (isSendingCommentForPlayer) {
      return;
    }

    if (commentForPlayer === "") {
      toastr.error(t("added-comment-message-error.label"));
      return;
    }

    try {
      setIsSendingCommentForPlayer(true);

      const BODY = {
        id: MISSION_ID,
        comment: commentForPlayer,
      };

      setCommentForPlayer("");

      const { data } = await MissionServices.sendMissionComment(BODY);
      otherPublic[index].comments.push(data);

      document.getElementById(`comment-content-${MISSION_ID}`).value = "";

      setIsSendingCommentForPlayer(false);
      toastr.success(t("added-comment.label"));
    } catch (error) {
      toastr.error(t("added-comment-message-error.label"));
      console.log(error);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragging(false);
  };

  const handleDragStart = (event) => {
    event.dataTransfer.setData("text/plain", event.target.id);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const { files } = event.dataTransfer;

    if (files.length > 0) {
      setDragging(false);

      if (files[0].type.split("/")[0] + "" === "image") {
        setOpenCropImage(true);
        setOpenImageCrop(files[0]);
      } else {
        setFile(files[0]);
      }
      setFileName(files[0].name);
    }
  };

  const esValidoPorFechaDeVencimiento = () => {
    const fechaActual = new Date();
    const fechaEspecifica = new Date(mission.expirationDate);

    if (fechaActual >= fechaEspecifica) {
      const dia = fechaEspecifica.getDate();
      const mes = fechaEspecifica.getMonth() + 1;
      const anio = fechaEspecifica.getFullYear();

      const fechaFormateada = `${dia}/${mes}/${anio}`;
      toastr.error("No se puede votar o comentar esta mision porque vencio el " + fechaFormateada);
      
      return false;
    }

    return true;
  }

  const vote = (id, index) => {
    if (esValidoPorFechaDeVencimiento() == false) {
      return;
    }

    if (sesion.user.id == otherPublic[index].user.id) {
      toastr.error("No puedes votar tu imagen");
      return;
    }

    otherPublic[index].isVoted = true;
    setOtherPuclic([...otherPublic]);
    MissionServices.voteMission({ id: id })
      .then(() => {
        otherPublic[index].voters = [...otherPublic[index].voters, {
            "username": sesion.user.username,
            "name": sesion.user.name,
            "lastName": sesion.user.lastName,
        }];
        setOtherPuclic([...otherPublic]);
        toastr.success(t("thanks-voting", "Thanks for voting"));
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data.messageCode ===
            "mission.user.only.one.vote.allowed"
        ) {
          toastr.error(t("vote-not-allowed", "Only one vote is allowed"));
        }
      });
  };

  const openModalFunction = (open, image, videoModal = false) => {
    setOpenModal(open);
    setVideoModal(videoModal);
    setVideoFromYoutube(false);

    if (image && image.includes("youtube")) {
      const position_one = image.indexOf("www.youtube.com/watch?v=");
      image =
        "https://www.youtube.com/embed/" + image.substr(position_one + 24, 11);
      setVideoFromYoutube(true);
    }
    if (open) {
      setImageModal(image);
    } else {
      setImageModal(null);
    }
  };

  const handleArchieve = (fileToSave) => {
    const fileType = fileToSave.type.split("/")[0];
    if (fileType === "audio" || fileType === "video") {
      const formData = new FormData();
      formData.append("file", fileToSave, fileToSave.name);
      FileService.uploadFile(formData, "mission_user")
        .then((response) => {
          let url = response.data.url;
          return MissionServices.achieveMission({
            id: id,
            file: url,
            comment: comment,
          });
        })
        .catch((error) => {
          toastr.error(t("admin.mission-message-error", "Fatal error."));
        })
        .finally(() => {
          setMissionCompleted(true);
          setFetching(false);
        });
      return;
    }

    const MAX_WIDTH = 1200;
    const MAX_HEIGHT = 1200;
    const MIME_TYPE = "image/jpeg";
    const QUALITY = 0.7;
    setFetching(true);

    const blobURL = window.URL.createObjectURL(fileToSave);

    const img = new Image();
    img.src = blobURL;

    img.onload = function () {
      const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const canvas = document.createElement("CANVAS");
      canvas.width = newWidth;
      canvas.height = newHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(
        (blob) => {
          if (!blob || blob.size > MAX_FILE_SIZE) {
            toastr.error(
              t(
                "admin.mission-user-file-size-error",
                "File must be 3Mb size or less."
              )
            );
            return;
          }

          const formData = new FormData();
          formData.append("file", blob, fileToSave.name);
          FileService.uploadFile(formData, "mission_user")
            .then((response) => {
              let url = response.data.url;
              return MissionServices.achieveMission({
                id: id,
                file: url,
                comment: comment,
              });
            })
            .then((resp) => {
              jsConfetti
                .addConfetti({
                  emojis: ["😀", "👍"],
                  emojiSize: 40,
                  confettiNumber: 40,
                })
                .then(() => {
                  toastr.success(t("imported-file.label.new", "Gracias por participar, en breve revisaremos tu foto enviada :)"));
                  cleanVariables();
                  setMissionCompleted(true);
                  setOtherPuclic([...otherPublic, resp.data]);
                });
            })
            .catch((error) => {
              toastr.error(t("admin.mission-message-error", "Fatal error."));
            })
            .finally(() => {
              setFetching(false);
            });
        },
        MIME_TYPE,
        QUALITY
      );
    };
  };

  const cleanVariables = () => {
    setComment("");
    setFileName("");
  };

  /*useEffect(() => {

    const timeout = setTimeout(() => {
      setUserSearch(userSearch);

      MissionServices.getMission(id, true, 6, 0, userSearch)
        .then((resp) => {
          setOtherPuclic(resp.data.content[0].otherPuclic);
        })
        .catch(() => {});
    }, 2000);

    return () => clearTimeout(timeout);
  }, [userSearch]);*/

  const onButtonSearch = () => {    
    MissionServices.getMission(id, true, 6, 0, userSearch)
      .then((resp) => {
        setOtherPuclic(resp.data.content[0].otherPuclic);
      })
      .catch(() => {});
  };


  const handleChangeUser =  (event) => {
    setUserSearch(event.target.value)
  };

  const handleConfirm = () => {
    if (missionCompleted) {
      toastr.error("Usted ya ha participado");
      cleanVariables();
      return;
    }
    if (file != null) {
      handleArchieve(file);
      return;
    }
    handleArchieveYoutube();
  };

  const handleArchieveYoutube = () => {
    if (!youtubeLink) {
      toastr.error(
        t("add-youtube-link-need.label", "You must add a youtube link")
      );
      return;
    }
    MissionServices.achieveMission({
      id: id,
      file: youtubeLink,
      comment: comment,
    })
      .then((resp) => {
        jsConfetti
          .addConfetti({
            emojis: ["😀", "👍"],
            emojiSize: 40,
            confettiNumber: 40,
          })
          .then(() => {
            setOtherPuclic([...otherPublic, resp.data]);
            setMissionCompleted(true);
            cleanVariables();
            toastr.success(t("imported-file.label", "File imported."));
          });
      })
      .catch((error) => {
        toastr.error(t("admin.mission-message-error", "Fatal error."));
      })
      .finally(() => {
        setFetching(false);
      });
    return;
  };

  function getImageFromDialog(img, urlimg) {
    let aux = img;
    setFileName(aux.name);
    setUrlImage(urlimg);
    setFile(aux);
    setOpenCropImage(false);
  }

  const inputFile = useRef(null);

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const handleScroll = () => {
    if (!divRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = divRef.current;
    const threshold = 50;

    if ((scrollTop + clientHeight >= scrollHeight - threshold) && loadingComments == false && noMoreComments == false) {
      setLoadingComments(true);
      let ind = indexComments+1;
      setIndexComments(indexComments+1);
      MissionServices.getMission(id, true, 6, ind)
        .then((resp) => {
          setLoadingComments(false);
          if (resp.data.content[0].otherPuclic.length == 0) {
            setNoMoreComments(true);
          } else {
            setOtherPuclic([...otherPublic, ...resp.data.content[0].otherPuclic]);
          }
        })
        .catch(() => {});
        // Aquí ejecutas tu evento
    }
};

  if (sesion === null) return "";

  return (
    <>
      {(mission.length === 0 && user.length === 0) ? (
        <Loader />
      ) : (
        <div className="mission-wrapper card-model">
          <div className="quiz-controls">
            <div className="trivia-name">
              <ForumIcon style={{ marginRight: '10px' }} />
              {mission.name.toUpperCase()}
            </div>
            <div className="right-side">
              <div className="right-side-div">
                <div className="points-bar">
                  <div style={{ height: '2em' }} className="progress">
                  <img src={newIconStar} alt="Custom Icon"  />
                    <strong style={{ color: 'white' }}>{mission.points} pts</strong>
                  </div>
                </div>
              </div>
              <span className="exit-button-wrapper">
                <NavLink to="/missions">
                  <Button style={{ marginRight: '1em' }}>
                    <MeetingRoomIcon fill="#CC3134" />
                  </Button>
                </NavLink>
              </span>
            </div>
          </div>
          {missionCompleted === false && (
            <>
              <div className="question-wrapper">
                <div className="mid-side">
                  <div className="mid-side-image" style={{ backgroundImage: `url(${environment.motivarnosBackend}${mission.image})` }}></div>
                </div>
                <div className="mid-side">
                  <div
                    className={`mid-side-load-file ${dragging || fileName !== '' ? "mid-side-load-file-drag-over" : ""}`}
                    onDragLeave={handleDragLeave}
                    onDragStart={handleDragStart}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    draggable="true"
                  >
                    {fileName !== '' ? (
                      <div className="mid-side-file-name">
                        <img alt="Crop" style={{ width: '100%', height: '100%' }} src={urlImage} />
                      </div>
                    ) : (
                      <div className="card-activity-button-container">
                        <div className="card-activity-photo-icon">
                          <AddAPhotoIcon style={{ fill: '#D7D7D7', width: '55px', height: '100px' }} />
                        </div>
                        <div className="card-activity-searcher">
                          <input onChange={handleFile} type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                        </div>
                        <div className="card-activity-subtitle">
                          <div><div></div></div>
                          <span>o suelta un archivo</span>
                          <div><div></div></div>
                        </div>
                        <div className="card-activity-footer">
                          <ButtonM onClick={onButtonClick} disabled={fetching} className="button-activity" >
                            <CloudUploadIcon style={{ fill: 'white', marginRight: '8px' }} /> Cargar Archivo
                          </ButtonM>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="div-confirm">
                <div className="div-confirm-right">
                  <TextField
                    id="outlined-multiline-static"
                    style={{ width: '95%' }}
                    label="Comentario"
                    multiline
                    disabled={fetching || (file == null && youtubeLink === '')}
                    onChange={(e) => setComment(e.target.value)}
                    rows={2}
                  />
                </div>
                <div className="div-confirm-left">
                  <ButtonM
                    style={{ width: '90%' }}
                    disabled={fetching || (file == null && youtubeLink === '')}
                    className="button-activity"
                    onClick={() => handleConfirm()}
                  >
                    <CheckCircleOutlineIcon style={{ fill: 'white', marginRight: '8px' }} />
                    {t("confirm.label")}
                  </ButtonM>
                </div>
              </div>
            </>
          )}
          <CropImageDialog
            open={openCropImage}
            openImageCrop={openImageCrop}
            getImageFromDialog={(img, urlimg) => getImageFromDialog(img, urlimg)}
          />
          {otherPublic.length > 0 && (
            <>
              <div className="quiz-controls" style={{ marginTop: '1.1rem', marginBottom: '1.1rem' }}>
                <div className="trivia-name" id="ver-participantes">
                  <AssistantIcon style={{ marginRight: '10px' }} /> Buscar participante:
                  <TextField
                    name="textAnswer"
                    onChange={handleChangeUser}
                    style={{marginLeft: '30px'}} 
                    label={"Nombre"}
                    placeholder={t("write-search.label", "Write your search")}
                    variant="outlined"
                  />
                  <ButtonM onClick={onButtonSearch} className="button-activity" style={{marginTop: '0px', marginLeft: '10px'}}>
                    <SearchIcon style={{ fill: 'white', marginRight: '8px' }} /> Buscar
                  </ButtonM>
                </div>
              </div>
              <div className="mission-winners" ref={divRef} onScroll={handleScroll}>
                  {otherPublic.map((data, index) => (
                    <div className="mission-winners-item" key={data.id}>
                      <div
                        className="mission-winners-item-header"
                        onClick={() => openModalFunction(true, `${data.file}`, data.file.includes('youtube'))}
                        style={{ backgroundImage: `url(${data.file.includes('youtube') ? novedad_logo : `${environment.motivarnosBackend}${data.file}`})` }}
                      >
                        <div className="mission-winners-item-header-button-like">
                          {(expirationDate != null && expirationDate > currentDate) && (
                            <div
                              className={`${data.isVoted ? "mission-is-voted" : "mission-is-no-voted"}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                vote(data.id, index);
                              }}
                            >
                              <ThumbUpIcon />
                            </div>
                          )}
                        </div>
                        <Avatar size="3em" avatar={data.user.image ? `${environment.motivarnosBackend}${data.user.image}` : avatar} />
                      </div>
                      <div className="mission-winners-item-body">
                        <div className="mission-winners-item-body-name">{data.user.name} {data.user.lastName}</div>
                        {user.id && user.id === data.user.id && (
                          <div
                            className={
                              data.status === 'ACHIEVED'
                                ? 'mission-winners-item-body-status2'
                                : data.status === 'UNACHIEVED'
                                ? 'mission-winners-item-body-status3'
                                : 'mission-winners-item-body-status'
                            }
                          >
                            {data.status === 'COMPLETED' 
                              ? 'COMPLETADO' 
                              : data.status === 'ACHIEVED' 
                              ? 'LOGRADO' 
                              : data.status === 'UNACHIEVED' 
                              ? 'NO LOGRADO' 
                              : data.status}
                          </div>
                        )}
                        <div className="mission-winners-item-body-subtitle">
                          <strong>``</strong>{data.comment}<strong>´´</strong>
                        </div>
                        {(data.voters != undefined && data.voters.length > 0) && (
                          <div className="mission-winners-item-body-voters">
                            Votado por: {data.voters.map((elem, ind) => `${elem.name} ${elem.lastName}${ind == data.voters.length-1 ? '.' : ', '}`)}
                          </div>
                        )}
                        <div className="mission-winners-item-body-start-comments"><div></div></div>
                        <div className="mission-winners-item-body-comments">
                          {data.comments.length === 0 ? (
                            <div className="mission-winners-item-body-no-comments">No hay comentarios</div>
                          ) : (
                            data.comments.map((comment) => (
                              <div className="mission-winners-item-body-comments-item" key={comment.id}>
                                <div className="mission-winners-item-body-comments-item-avatar">
                                  <Avatar size="2em" avatar={comment.user.image ? `${environment.motivarnosBackend}${comment.user.image}` : avatar} />
                                </div>
                                <div className="mission-winners-item-body-comments-item-message">
                                  <span>{comment.user.name}</span>
                                  <p>{comment.comment}</p>
                                </div>
                                <div className="mission-winners-item-body-comments-item-division"><div></div></div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                      <div className="mission-winners-item-footer" style={{backgroundColor: (expirationDate != null && expirationDate > currentDate) ? 'none' : '#FBFBFB'}}>
                        {(expirationDate != null && expirationDate > currentDate) && (<>
                          <div className="mission-winners-item-footer-avatar">
                            <Avatar size="2.4em" avatar={data.user.image ? `${environment.motivarnosBackend}${data.user.image}` : avatar} />
                          </div>
                          <div className="mission-winners-item-footer-input">
                            <Input
                              className="comment-message"
                              placeholder="Responde aquí"
                              onChange={(e) => handleChangeComment(e)}
                              onKeyDown={(event) => handleKeySend(event, data.id, index)}
                              id={`comment-content-${data.id}`}
                            />
                          </div>
                          <div className="mission-winners-item-footer-send-icon">
                            <div>
                              <SendIcon onClick={() => handleSendComment(data.id, index)} />
                            </div>
                          </div>
                        </>)}
                      </div>
                      <div className="mission-winners-item-footer-input">
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
          {openModal && (
            <ShowImageDialog
              openModal={openModal}
              videoModal={videoModal}
              imageModal={imageModal}
              openModalFunction={(open) => openModalFunction(open)}
              videoFromYoutube={videoFromYoutube}
            />
          )}
        </div>
      )}
    </>
  );
  
};

export default Mission;
