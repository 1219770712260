import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import { useSelector } from "react-redux";
import Loading from "../../common/controls/LoadingWindow";
import GoogleLoginButton from "./GoogleLoginButton";
import { FormattedMessage } from "react-intl";
import DialogContent from "@material-ui/core/DialogContent";
import Container from "@material-ui/core/Container/Container";
import { Grid, Paper } from '@material-ui/core'
import { textStyles, inputStyles, labelStyles } from "../../styles/styles";
import Error from "../../common/errorHandler/ErrorHandler";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import Veolia from "../../../../assets/img/veolia.png"
import axios from "axios";
import { Box, IconButton, Tooltip } from '@material-ui/core';
import PlayArrow from "@material-ui/icons/PlayArrow";
import Pause from "@material-ui/icons/Pause";
import '../../styles/demo.css'
import songVeolia from "../../../../assets/music/Veolia-intro.wav";
import useMusic from "../../../../hooks/useMusic";

let companyName = "demo";

function PlayerButton({ isPlaying, handlePlay }) {
  return (
      <Box pl={1} onClick={handlePlay}>
          <IconButton className="play-btn" color="primary" aria-label="play music" component="span">
              {
                  !isPlaying ?
                      <Tooltip title={'Play'} id="play-btn-selector">
                          <PlayArrow />
                      </Tooltip>
                      :
                      <Tooltip title={'Pause'}>
                          <Pause />
                      </Tooltip>
              }
          </IconButton>
      </Box>
  );
}

const useReduxSelector = () =>
  useSelector((state) => ({
    signupCompleted: state.authReducer.signupCompleted,
    error: state.authReducer.error,
  }));

const VeoliaLoginLanding = ({ padding }) => {
  const location = useLocation();
  const classesText = textStyles();
  const classesLabel = labelStyles();
  const classesInput = inputStyles();
  const { error } = useReduxSelector();
  const reloadGoogleLogin = false;
  const [name,setName] = useState("");
  const [email,setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [isPlaying, togglePlayMusic] = useMusic(songVeolia);
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;

  const isValid = name && email && regex.test(email);

  useEffect(() => {
    togglePlayMusic()
  }, []);
  
  const loginHandler = () => {
    setIsLoading(true)
    axios.post("/login", {
        company: companyName,
        name: name,
        email: email,
        type: 'presentacion'
    }).then(r=>{

      localStorage.setItem(
        "sesion",
        JSON.stringify({
          ...r.data,
          expiresAt: new Date().getTime(),
        })
      );

      if(location.pathname == '/academia') {
        localStorage.setItem('gamificaMode', true)
      } else {
        localStorage.setItem('showroomMode', true)
      }

      window.location.replace("/");
    }).finally(()=>setIsLoading(false));
  };

  const handleCompanyName = async (value) => {
    setName(value);
  }

  const loginGmailHandler = (data) => {

    setIsLoading(true)
    axios.post("/login", {
      tokenId: data.credential,
      useremail: data.Is ? data.Is.ot : "",
      company: companyName,
      type: 'presentacion',
    }).then(r=>{
      console.info(r)
      localStorage.setItem(
        "sesion",
        JSON.stringify({
          ...r.data,
          expiresAt: new Date().getTime(),
        })
      );

      if(location.pathname == '/academia') {
        localStorage.setItem('gamificaMode', true);
      } else {
        localStorage.setItem('showroomMode', true);
      }

      window.location.replace("/");
    }).finally(()=>setIsLoading(false));
  };

  const getError = (error) => {
    if (error && error.error) {
      if (error.error.messageCode === "auth.invalidusername") {
        return t("response-login-401.label", "Invalid username/password");
      } else if (error.error.messageCode === "No value present") {
        return t(
          "response-login-500-company.label",
          "Write the name of the company, please"
        );
      } else if (
        error.error.messageCode === "auth.gmail.multiple.user.no.company"
      ) {
        return t(
          "response-login-500-company.label",
          "Write the name of the company, please"
        );
      } else if (error.error.messageCode === "auth.no.web.login.allowed") {
        return t(
          "response-login-401-not-allowed.label",
          "Web login is not allowed"
        );
      } else {
        return t(
          "response-login-500.label",
          "There was an error, please try again later"
        );
      }
    } else {
      return t(
        "response-login-500.label",
        "There was an error, please try again later"
      );
    }
  };

  const paperStyle = {
    padding: padding === 0 ? padding : (padding !== 20) ? padding : 20,
    width: "50vh",
    margin: "0 auto",
    backgroundColor: "#fafafa",
    boxShadow: "None",
    opacity: 1
   
  }

  const pageStyle = {
    opacity: 0.95
  }

  return (
    <div>
      {isLoading && <Loading open={true}></Loading>}
        <Grid style={pageStyle}>
          <PlayerButton
            isPlaying={isPlaying}
            handlePlay={() => togglePlayMusic()}
          />
          <Paper style={paperStyle}>
            <DialogContent>
              <form onSubmit={loginHandler}>
                <Container
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    justifyContent: "center",
                    
                  }}
                >
                  {!isLoading && error && (
                    <>
                      <Error
                        style={{ width: "17vw", alignSelf: "center" }}
                        message={getError(error)}
                      ></Error>
                    </>
                  )}
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src={Veolia} alt="logo-header" style={{width: "100%"}} />
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <label  style={{width:'100%', textAlign: 'center', fontSize: '2rem'}}>MOTIVARNOS</label>
                  </div>
                  <label className={classesLabel.label}>Nombre</label>
                  <TextField
                    value={name}
                    onChange={(e) => handleCompanyName(e.target.value)}
                    className={classesInput.textField}
                    style={{ marginBottom: 12, backgroundColor: "#F6F8FA" }}
                    variant="outlined"
                    fullWidth
                    placeholder="Escribe tu nombre..."
                    size="small"
                  />
                  <label className={classesLabel.label}>{t("email.label")}</label>
                  <TextField
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className={classesInput.textField}
                    variant="outlined"
                    fullWidth
                    placeholder="tuemail@ejemplo.com"
                    size="small"
                  />
                  <Button
                    disabled={!isValid}
                    onClick={loginHandler}
                    style={{
                      margin: "8px 0",
                      backgroundColor:  "#EAF0F6",
                      fontSize:  "1.3rem",
                      fontWeight: "bold",
                    }}
                    fullWidth
                  >
                    <FormattedMessage
                      id="login.confirmBtnz"
                      defaultMessage={"A Jugar"}
                      className={classesText.textButton}
                      style={{ color: "#3508e7" }}
                    />
                  </Button>
                  <hr style={{
                    width: "100%",
                    border: "0.5px solid #EEEEEE",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}/>
                </Container>
              </form>
              <GoogleLoginButton
                reloadGoogleLogin={reloadGoogleLogin}
                shape="pill"
                size="large"
                text="continue_with"
                theme="outline"
                onSuccess={loginGmailHandler}
                company={'prueba'}
              />
            </DialogContent>
        </Paper>
      </Grid>
    </div>
  );
};

export default VeoliaLoginLanding;
