import React, { useState, useEffect, useRef } from "react";
import map from "../../assets/img/map.jpg";
import "./styles.scss";
import "toastr/build/toastr.min.css";


const Quizzes = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCorrect, setIsOpenCorrect] = useState(false);
  const [isOpenIncorrect, setIsOpenIncorrect] = useState(false);

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setAnimate(true), 10); // Pequeño delay para activar la animación
    } else {
      setAnimate(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpenIncorrect) {
      setTimeout(() => setAnimate(true), 10); // Pequeño delay para activar la animación
    } else {
      setAnimate(false);
    }
  }, [isOpenIncorrect]);

  useEffect(() => {
    if (isOpenCorrect) {
      setTimeout(() => setAnimate(true), 10); // Pequeño delay para activar la animación
    } else {
      setAnimate(false);
    }
  }, [isOpenCorrect]);
  
  const handleOpenModal = () => {
    setIsOpen(true)
    setIsOpenCorrect(false)
    setIsOpenIncorrect(false)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
    setIsOpenCorrect(false)
    setIsOpenIncorrect(false)
  }

  const handleCorrect = () => {
    setIsOpen(false)
    setIsOpenCorrect(true)
  }

  const handleIncorrect = () => {
    setIsOpen(false)
    setIsOpenIncorrect(true)
  }

  return (
    <div

      className="container-game"
      style={{
        display: 'flex', 
        justifyContent: 'center', 
        backgroundImage: `url(${map})`, 
        width: '1000px', 
        height: '600px', 
        marginLeft: '70px'
      }}
    >
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            background: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => setIsOpen(false)}
        >
          <div
            className={`modal ${animate ? "show" : ""}`}
            onClick={(e) => e.stopPropagation()}
          >
            <h2>🎉 ¡Elije!</h2>
            <p>Si quieres avanzar en el juego deberias elegir correctamente una de las siguientes opciones 🤓</p>
            {/*<button onClick={() => setIsOpen(false)}>Cerrar</button>*/}
            <div className="option-modal-game" onClick={() => handleIncorrect()} style={{backgroundColor: "#ffffae"}}><div className="option-modal-game-2" style={{borderColor: "yellow"}}>Estoy seguro que esta es la opcion equivocada 😏</div></div>
            <div className="option-modal-game" onClick={() => handleIncorrect()} style={{backgroundColor: "#aedfff"}}><div className="option-modal-game-2" style={{borderColor: "#0099ff"}}>Estoy seguro que esta podria ser la opcion correcta 😈</div></div>
            <div className="option-modal-game" onClick={() => handleCorrect()} style={{backgroundColor: "#94ffcf"}}><div className="option-modal-game-2" style={{borderColor: "#09fa8e"}}>Estoy seguro que esta es la opcion correcta 😇</div></div>
          </div>
        </div>
      )}
      {isOpenCorrect && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            background: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => setIsOpenCorrect(false)}
        >
          <div
            className={`modal ${animate ? "show" : ""}`}
            onClick={(e) => e.stopPropagation()}
          >
            <h2>🎉😎 ¡Lo lograste! 😎🎉</h2>
            <p>Elejiste la opcion correcta, ahora puedes avanzar a la siguiente etapa</p>
            <div className="option-modal-game" onClick={() => handleCloseModal()} style={{backgroundColor: "#94ffcf"}}><div className="option-modal-game-2" style={{borderColor: "#09fa8e"}}>Terminar</div></div>
          </div>
        </div>
      )}
      {isOpenIncorrect && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            background: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => setIsOpenIncorrect(false)}
        >
          <div
            className={`modal ${animate ? "show" : ""}`}
            onClick={(e) => e.stopPropagation()}
          >
            <h2>Fallaste, pero puedes volver a elegir 💪</h2>
            <p>Tienes otra oportunidad, elije saviamente 🧙</p>
            <div className="option-modal-game" onClick={() => handleCloseModal()} style={{backgroundColor: "#94ffcf"}}><div className="option-modal-game-2" style={{borderColor: "#09fa8e"}}>Terminar</div></div>
            {/*<button onClick={() => setIsOpen(false)}>Cerrar</button>*/}
          </div>
        </div>
      )}
      {<img
        src={map}
        useMap="#image-map"
        alt="Ejemplo"
        style={{ width: "1000px", height: "600px"}}
      />}
      <div
        onClick={() => handleOpenModal()}
        className="selection"
        style={{
          top: '150px',
          left: '430px',
          width: '160px',
          height: '65px',
        }}
      />
      <div
        onClick={() => handleOpenModal()}
        className="selection"
        style={{
          top: '150px',
          left: '608px',
          width: '160px',
          height: '65px',
        }}
      />
      <div
        onClick={() => handleOpenModal()}
        className="selection"
        style={{
          top: '150px',
          left: '788px',
          width: '160px',
          height: '65px',
        }}
      />
      <div
        onClick={() => handleOpenModal()}
        className="selection"
        style={{
          top: '230px',
          left: '788px',
          width: '160px',
          height: '65px',
        }}
      />
      <div
        onClick={() => handleOpenModal()}
        className="selection"
        style={{
          top: '226px',
          left: '432px',
          width: '151px',
          height: '117px',
        }}
      />
      <div
        onClick={() => handleOpenModal()}
        className="selection"
        style={{
          top: '640px',
          left: '434px',
          width: '150px',
          height: '40px',
        }}
      />
      <div
        onClick={() => handleOpenModal()}
        className="selection"
        style={{
          top: '640px',
          left: '606px',
          width: '150px',
          height: '40px',
        }}
      />
      <div
        onClick={() => handleOpenModal()}
        className="selection"
        style={{
          top: '640px',
          left: '778px',
          width: '150px',
          height: '40px',
        }}
      />
    </div>
  );
};

export default Quizzes;