import React from "react";
import { es } from "date-fns/locale";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import Delete from "../../common/Dialogs/Delete";
import { withTranslation } from "react-i18next";
import GeneralFunctions from "../../libs/GeneralFunctions";
import { connect } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import LetterSoupService from "../../Services/LetterSoupService";
import GameResets from "../../common/Dialogs/GameResets";
import {
  teamsRequest,
  usersRequest,
  letterSoupRequest,
  skillsRequest,
  secondaryTeamsRequest,
  imagesRequest,
} from "../../../../../actions/generalActions";
import {
  getLetterSoupRequest,
  deleteLetterSoupRequest,
} from "../../../../../actions/letterSoupActions";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import LetterSoupDialog from "../../common/Dialogs/LetterSoup";
import LetterSoupClue from "../../common/Dialogs/LetterSoupClue";
import LetterSoupDetails from "./LetterSoupDetails";
import "toastr/build/toastr.min.css";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },

  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class LetterSoupPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 5,
      verDetalles: false,
      detalleData: [],
      detalleCargando: false,
      detalleNombre: ''
    };
  }

  componentDidMount() {

    if (!this.props.generalReducer.users) {
      this.props.usersRequest();
    }

    if (!this.props.generalReducer.teams) {
      this.props.teamsRequest();
    }
    if (!this.props.generalReducer.secondary_teams) {
      this.props.secondaryTeamsRequest();
    }
    this.props.getLetterSoupRequest(1);
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.props.getLetterSoupRequest(value + 1);
  }

  extractWordOfRulesJson(rules){
     if(rules !== '' && rules.type === 'sugerente' && rules.clues.length){
        let pal = [];
        let auxwords=rules.clues;
        auxwords.forEach(element => {
          pal.push(element.word);
       });
       return pal.join(', ');       
     }
     return;
  }

  async deleteLetterSoup(id) {
    let status;
    await this.props.deleteLetterSoupRequest(id).then((r) => (status = r));

    if (status === "SUCCESS") {
      toastr.success(
        this.props.t(
          "menu.lettersoup-panel-message-success-delete-lettersoup",
          "Letter Soup removed successfully"
        )
      );
      return;
    }

    if (status.error && status.error.status === 400) {
      toastr.error(
        this.props.t(
          "admin.lettersoup-panel-message-error-delete-letterSoup-one",
          "This Letter Soup is being used and cannot be removed"
        )
      );
    } else {
      toastr.error(
        this.props.t(
          "menu.lettersoup-panel-message-error-delete-letterSoup-two",
          "An error occurred while deleting the letter letterSoup"
        )
      );
    }
  }

  handleAbrirDetalles(data) {
    this.setState({verDetalles: true, detalleCargando: true, detalleNombre: data.name });
    LetterSoupService.getScores(data.id)
        .then(r=>{
            this.setState({verDetalles: true, detalleData: r.data.content,detalleCargando: false });
        })
        .finally(()=>this.setState({detalleCargando: false }))
  }

  resetSopa (id) {
    this.setState({detalleCargando: true});
    LetterSoupService.deleteScore(id)
      .then(() => {
        this.setState({detalleData: this.state.detalleData.filter(item => item.id !== id)});
        toastr.success(
          "Sopa de letras reseteada con éxito."
        );
      }).catch(e => {
        toastr.success(
          "Error reseteando sopa de letras."
        );
      })
      .finally(() => {
        this.setState({detalleCargando: false});
      });
  };

  render() {
    const { rowsPerPage, page, verDetalles, detalleData, detalleCargando, detalleNombre } = this.state;
    const { classes, t, i18n } = this.props;
    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {(this.props.letterSoupReducer.lettersoup_charging ||
            this.props.generalReducer.teams_charging ||
            this.props.generalReducer.secondary_teams_charging ||
            this.props.generalReducer.users_charging ||
            this.props.generalReducer.lettersoup_charging ||
            this.props.generalReducer.skills_charging ||
            this.props.generalReducer.images_charging) && (
              <div className="div-spinner">
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </div>
            )}

          {!this.props.letterSoupReducer.lettersoup_charging &&
            !this.props.generalReducer.teams_charging &&
            !this.props.generalReducer.secondary_teams_charging &&
            !this.props.generalReducer.users_charging &&
            !this.props.generalReducer.lettersoup_charging &&
            !this.props.generalReducer.images_charging &&
            (this.props.letterSoupReducer.lettersoup &&
              this.props.letterSoupReducer.lettersoup.length > 0 ? (
              <Grid container spacing={1}>
                {/*this.props.generalReducer.teams &&
                  this.props.generalReducer.secondary_teams &&
                  this.props.generalReducer.users &&
                  this.props.generalReducer.images && (
                    <LetterSoupDialog
                      rowTeams={this.props.generalReducer.teams.content}
                      rowSecondaryTeams={
                        this.props.generalReducer.secondary_teams.content
                      }
                      rowsUsers={this.props.generalReducer.users.content}
                      firstImage={
                        this.props.generalReducer.images.content[0].url
                      }
                    />
                    )*/}

                  <LetterSoupDialog
                          rowTeams={this.props.generalReducer.teams.content}
                          rowSecondaryTeams={
                            this.props.generalReducer.secondary_teams.content
                          }
                          rowsUsers={this.props.generalReducer.users.content}
                          getLetterSoup={() => this.getLetterSoup(page, true)}
                  />

                  <LetterSoupClue
                          rowTeams={this.props.generalReducer.teams.content}
                          rowSecondaryTeams={
                            this.props.generalReducer.secondary_teams.content
                          }
                          rowsUsers={this.props.generalReducer.users.content}
                          getLetterSoup={() => this.getLetterSoup(page, true)}
                  />
                   

                 

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("admin.user-panel-user-dialog-input-name", "Name")}
                        </TableCell>
                        <TableCell align="left">
                          {t("menu.lettersoup-panel-table-description","Description"
                          )}
                        </TableCell>
                        {<TableCell align="left">
                          {t("menu.lettersoup-panel-table-words", "Words")}
                        </TableCell>}

                        <TableCell align="left">
                          {t("creation-date.label", "Creation date")}
                        </TableCell>

                        <TableCell align="left">
                          {t("expiration-date.label", "Expiration date")}
                        </TableCell>

                        <TableCell align="left">
                          {t("hide-date.label", "Hide date")}
                        </TableCell>

                        <TableCell align="left">
                          {t("guests-inhouse", "Guests InHouse")}
                        </TableCell>

                        
                        <TableCell align="left">
                          Participantes Inhouse
                        </TableCell>

                        <TableCell align="left">
                          {t("guests-bpo", "Guests BPO")}
                        </TableCell>
                        
                        <TableCell align="left">
                          Participantes BPO
                        </TableCell>

                        <TableCell align="left">
                          {t("guests", "Guests")}
                        </TableCell>
                        
                        <TableCell align="left">
                          Participantes
                        </TableCell>

                        <TableCell align="left">
                          {t("admin.user-panel-table-state", "State")}
                        </TableCell>
                        <TableCell align="left">
                          {t("admin.user-panel-table-actions", "Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {this.props.letterSoupReducer.lettersoup
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <TableRow key={row.id} >
                            <TableCell align="left"  onClick={() => this.handleAbrirDetalles(row)}>
                              {row.name.length > 30
                                ? row.name.substring(0, 30) + "..."
                                : row.name}
                            </TableCell>

                            <TableCell align="left">
                              {row.description && row.description.length > 30
                                ? row.description.substring(0, 30) + "..."
                                : row.description}
                            </TableCell>
                            
                            <TableCell align="left">
                              {(row.words===null)
                                ? (row.rules!==null  && row.rules.type==='sugerente' 
                                     ? this.extractWordOfRulesJson(row.rules): t("admin.lettersoup-panel-new-lettersoup-clue-name", "Clue LetterSoup added correctly")) 
                              : row.words }
                            </TableCell>

                            <TableCell align="left">
                              {GeneralFunctions.formatNewDate(
                                row.created,
                                es
                              )}
                            </TableCell>

                            <TableCell align="left">
                              {GeneralFunctions.formatNewDate(
                                row.expirationDate,
                                es
                              )}
                            </TableCell>

                            <TableCell align="left">
                              {row.hideOnDate
                                ? GeneralFunctions.formatNewDate(
                                  row.hideOnDate,
                                  es
                                )
                                : "N/A"}
                            </TableCell>

                            <TableCell align="left">
                              {row.invitedInhouseCount ? row.invitedInhouseCount : 0}
                            </TableCell>

                            <TableCell align="left">
                              {row.participantesInhouse ? row.participantesInhouse : 0}
                            </TableCell>

                            <TableCell align="left">
                              {row.invitedOutsourceCount ? row.invitedOutsourceCount : 0}
                            </TableCell>

                            <TableCell align="left">
                              {row.participantesBPO ? row.participantesBPO : 0}
                            </TableCell>

                            <TableCell align="left">
                              {row.invitedCount ? row.invitedCount : 0}
                            </TableCell>

                            <TableCell align="left">
                              {row.participantesInhouse + row.participantesBPO}
                            </TableCell>

                            <TableCell align="left">
                              {row.isActive === true &&
                                t(
                                  "admin.header-dropdown-view-conditions-table-active-state",
                                  "Active"
                                )}
                              {row.isActive !== true &&
                                t(
                                  "menu.lettersoup-panel-table-inactive",
                                  "Inactive"
                                )}
                            </TableCell>
                            <TableCell align="left">
                              <div style={{display: 'flex'}}>
                                {this.props.generalReducer.teams &&
                                  this.props.generalReducer.secondary_teams &&
                                  this.props.generalReducer.users && ((row.rules && row.rules.type==="sugerente")? 
                                  <LetterSoupClue
                                  {...row}
                                  rowTeams={
                                    this.props.generalReducer.teams.content
                                  }
                                  rowSecondaryTeams={
                                    this.props.generalReducer.secondary_teams
                                      .content
                                  }
                                  rowsUsers={
                                    this.props.generalReducer.users.content
                                  }
                                  typeModal="modalEdit"
                                />
                                :
                                <LetterSoupDialog
                                  {...row}
                                  rowTeams={
                                    this.props.generalReducer.teams.content
                                  }
                                  rowSecondaryTeams={
                                    this.props.generalReducer.secondary_teams
                                      .content
                                  }
                                  rowsUsers={
                                    this.props.generalReducer.users.content
                                  }
                                  typeModal="modalEdit"
                                />
                                  
                                  )}

                                <Delete
                                  title={t(
                                    "menu.lettersoup-panel-table-actions-delete-question-title",
                                    "Letter Soup management"
                                  )}
                                  deleteMetric={() => this.deleteLetterSoup(row.id)}
                                />

                                <GameResets game_id={row.id} gameType={"lettersoup"}/>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    className="mt-1"
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={this.props.letterSoupReducer.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, value) =>
                      this.handleChange(event, value)
                    }
                  />
                </TableContainer>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    {this.props.generalReducer.teams &&
                      this.props.generalReducer.secondary_teams &&
                      this.props.generalReducer.users && (
                        <LetterSoupDialog
                          rowTeams={this.props.generalReducer.teams.content}
                          rowSecondaryTeams={
                            this.props.generalReducer.secondary_teams.content
                          }
                          rowsUsers={this.props.generalReducer.users.content}
                          getLetterSoup={() => this.getLetterSoup(page, true)}
                        />
                      )}

                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
            <LetterSoupDetails 
              open={verDetalles} 
              title={detalleNombre} 
              data={detalleData} 
              cargando = {detalleCargando}
              onClose={()=> this.setState({ verDetalles: false })}
              onReset={(id) => this.resetSopa(id)}
              >
              
            </LetterSoupDetails>
        </Container>
      </main>
    );
  }
}

const mapStateToProps = ({ generalReducer, letterSoupReducer }) => ({
  generalReducer,
  letterSoupReducer,
});
const mapDispatchToProps = {
  teamsRequest,
  usersRequest,
  letterSoupRequest,
  skillsRequest,
  secondaryTeamsRequest,
  imagesRequest,
  getLetterSoupRequest,
  deleteLetterSoupRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withStyles(styles, { withTheme: true })(LetterSoupPanel)));
