import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { SpinnerCircular } from "spinners-react";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import environment from "../../libs/environment";
import novedad_logo from "../../assets/img/novedad-logo.png";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import toastr from "toastr";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { NavLink } from "react-router-dom";
import { format } from "date-fns";
import PeopleIcon from "@material-ui/icons/People";
import Button from "../../components/Button";
import useUserSesion from "../../hooks/useUserSesion";
import resumen_trivia from "../../assets/img/resumen-trivia.svg";
import ilustracion_gato from "../../assets/img/ilustracion-gato.png";
import MissionServices from "../../Services/Mission/Player";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "toastr/build/toastr.min.css";
import "./styles.scss";

const MissionCart = (props) => {
  const { t } = useTranslation();
  const [participo, setParticipo] = useState(false);
  const [finalizo, setFinalizo] = useState(false);
  const [otherPublic, setOtherPuclic] = useState(props.otherPuclic);

  useEffect(() => {
    const today = new Date(new Date().toDateString());
    const date = new Date(props.date);

    setFinalizo(today >= date);

    setParticipo(
      !props.isAvailable ||
        props.isCompleted ||
        today.getTime() > date.getTime()
    );
  }, [otherPublic, props.date, props.isAvailable, props.sesion.user.id, props.isCompleted]);

  const vote = (id, index) => {
    const updatedOtherPublic = [...otherPublic];
    const votedElem = updatedOtherPublic[index];
    const currentDate = new Date(); // Fecha y hora actual
    const expirationDate = new Date(votedElem.mission.expirationDate); // Fecha a comparar

    if (currentDate > expirationDate) {
      toastr.warning('Ya ha finalizado');
      return;
    }

    // Verifica si el elemento ya está votado
    if (votedElem.isVoted) {
      toastr.warning(t("already-voted", "You already voted"));
      return;
    }

    // Marca el elemento como votado
    votedElem.isVoted = true;
    votedElem.votes += 1; // Incrementa el número de votos

    setOtherPuclic(updatedOtherPublic); // Actualiza el estado con la lista modificada
    
    MissionServices.voteMission({ id: id })
      .then(() => {
        toastr.success(t("thanks-voting", "Thanks for voting"));
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data.messageCode ===
            "mission.user.only.one.vote.allowed"
        ) {
          toastr.error(t("vote-not-allowed", "Only one vote is allowed"));
          
          votedElem.isVoted = false;
          votedElem.votes -= 1; 
          setOtherPuclic(updatedOtherPublic);
        }
      });
  };

  return (
    <>
      <div className="card-model card-activity-mission">
        <Grid xs={12} md={2} xl={2} className="card-activity-image-container">
          <div className="mission-image-wrapper">
            <span
              className="mission-image"
              style={{
                backgroundImage: `url(${environment.motivarnosBackend}${props.image})`,
              }}
            ></span>
          </div>
        </Grid>
        <Grid xs={12} lg={5} className="card-activity-info-container">
          <div className="info-container-type">
            {/*<span className="span-quiz div-title-info"> <div><MonetizationOnIcon /></div><p>{props.points} pts</p></span>*/}
            {/*props.type == 'GROUP' && <span className="span-new div-title-info"> <div><PeopleIcon /></div><p>MultiUser</p></span>*/}
          </div>
          <div className="info-container-title">
            {props.name.charAt(0).toUpperCase() + props.name.slice(1)}
          </div>
          <div className="info-container-subtitle">{props.description}</div>
          <div className="info-container-state">
            <ErrorOutlineIcon
              style={{
                fill: "rgb(158, 158, 158)",
                width: "20px",
                height: "20px",
                marginRight: "2px",
              }}
            />
            {`${finalizo ? "Finalizó " : "Finaliza "} el ${format(
              new Date(props.date),
              "dd-MM-yyyy"
            )}`}
            <PeopleIcon
              style={{
                fill: "rgb(158, 158, 158)",
                width: "20px",
                height: "20px",
                marginRight: "2px",
                marginLeft: "6px",
              }}
            />
            Participantes {props.completedQty}
          </div>
        </Grid>
        <Grid xs={4} lg={7} className="card-activity-winers">
          {otherPublic.map((elem, index) => (
            <>
              {index < 4 && (
                <div className="card-activity-winers-item" key={elem.id}>
                  <div
                    className="card-activity-winers-item-head"
                    style={{
                      backgroundImage:
                        "url(" +
                        (elem.fileName != null
                          ? `${environment.motivarnosBackend}${elem.file}`
                          : novedad_logo) +
                        ")",
                    }}
                  ></div>
                  <div className="card-activity-winers-item-body">
                    <div className="card-activity-winers-item-body-name">
                      {elem.user.name}
                    </div>
                    <div className="card-activity-winers-item-body-icons">
                      <div className="mission-winners-item-header-button-like">
                        <div
                          className={`${
                            elem.isVoted
                              ? "mission-is-voted"
                              : "mission-is-no-voted"
                          }`}
                          onClick={(e) => {
                            e.stopPropagation(); // Evita que el evento se propague a otros elementos
                            vote(elem.id, index);
                          }}
                        >
                          <ThumbUpIcon />
                        </div>
                      </div>
                      <NavLink to={`/missions/mission?id=${props.id}`}>
                        <div>{elem.votes}</div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}
              {index === 4 && (
                <div className="card-activity-winers-item" key={index}>
                  <NavLink to={"/missions/mission?id=" + props.id}>
                    <div className="card-activity-winers-item-block">
                      <div className="card-activity-winers-item-ver-todos">
                        VER TODOS
                      </div>
                    </div>
                  </NavLink>
                  <div
                    className="card-activity-winers-item-head card-activity-winers-item-third"
                    style={{
                      backgroundImage:
                        "url(" +
                        (elem.fileName != null
                          ? `${environment.motivarnosBackend}${elem.file}`
                          : novedad_logo) +
                        ")",
                    }}
                  ></div>
                  <div className="card-activity-winers-item-body card-activity-winers-item-third">
                    <div className="card-activity-winers-item-body-name">
                      {elem.user.name}
                    </div>
                    <div className="card-activity-winers-item-body-icons">
                      <NavLink to={"/missions/mission?id=" + props.id}>
                        <div>
                          <div
                            className={`${
                              elem.isVoted
                                ? "mission-is-voted"
                                : "mission-is-no-voted"
                            }`}
                            onClick={(e) => {
                              e.stopPropagation(); 
                              vote(elem.user.id, index);
                            }}
                          >
                            <ThumbUpIcon /> 
                          </div>
                        </div>
                      </NavLink>
                      <NavLink to={"/missions/mission?id=" + props.id}>
                        <div>
                          <ChatBubbleIcon />
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </Grid>
        <Grid xs={12} lg={3} className="card-activity-button-container">
          {!participo && (
            <NavLink to={"/missions/mission?id=" + props.id}>
              <Button className="button-activity">
                Participar{" "}
                <SportsEsportsIcon
                  style={{ fill: "white", marginLeft: "8px" }}
                />
              </Button>
            </NavLink>
          )}
          <NavLink to={"/missions/mission?id=" + props.id}>
            <Button className="button-activity-transparent">
              VER PARTICIPANTES{" "}
              <span className="button-activity-signo">{">"}</span>
            </Button>
          </NavLink>
        </Grid>
      </div>
    </>
  );
};

const Missions = () => {
  const { t } = useTranslation();

  const [missions, setMissions] = useState(null);
  const [sesion] = useUserSesion();

  const getMissions = () => {
    setMissions(null);
    MissionServices.getMissionsList().then((resp) => {
      setMissions(resp.data.content);
    });
  };

  const changeFilter = (option) => {
    alert(option);
  };

  useEffect(() => {
    getMissions();
  }, []);

  if (sesion == null) {
    return null;
  }

  return (
    <div className="challenges-wrapper">
      {missions === null ? (
        <span className="spinner-container">
          <SpinnerCircular />
        </span>
      ) : (
        <div className="challenges-container">
          <div className="mission-title">{t("missions.label")}</div>
          {missions.length == 0 ? (
            <div className="card-model card-activity-mission no-missions">
              <div>
                <img
                  className="osito-resumen"
                  src={
                    sesion.company.id === 5 ? ilustracion_gato : resumen_trivia
                  }
                  alt=""
                />
              </div>
              <div>
                <h4>No tienes ninguna misión asignada</h4>
              </div>
            </div>
          ) : (
            <div className="missions-wrapper">
              {missions.map((data, index) => (
                <MissionCart
                  completedQty={data.mission.completedQty}
                  otherPuclic={data.otherPuclic}
                  key={index}
                  name={data.mission.name}
                  points={data.mission.points}
                  type={data.mission.type}
                  date={data.mission.expirationDate}
                  avatar={"/api" + data.user.image}
                  image={data.mission.image}
                  creatorAvatar={"/api" + data.mission.creator.image}
                  sesion={sesion}
                  id={data.id}
                  description={data.mission.description}
                  isAvailable={data.isAvailable}
                  isCompleted={data.isCompleted}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Missions;
