import * as suggestionInboxTypes from "../types/suggestionInboxTypes";

import SuggestionInboxService from "../components/app/admin/Services/SuggestionInboxService";
import UserService from "../components/app/admin/Services/UserService";

const {
  SUGGESTION_INBOX_ERROR,
  SUGGESTION_INBOX_CHARGING,
  SUGGESTION_INBOX_REQUEST,
  SUGGESTION_INBOX_UPDATE,
} = suggestionInboxTypes;

export const suggestionInboxRequest = (status = false) => async (dispatch) => {
  
  dispatch({
    type: SUGGESTION_INBOX_CHARGING,
  });
  try {
    const responseLogin = await SuggestionInboxService.getSuggestionInbox(status);
    const responseLogin2 = await UserService.getUsersIsSuggestionInboxOwner();

    responseLogin.data.UsersSuggestionInboxOwner = responseLogin2.data.content;

    dispatch({
      type: SUGGESTION_INBOX_REQUEST,
      payload: responseLogin.data,
    });
  } catch (error) {
    dispatch({
      type: SUGGESTION_INBOX_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const suggestionInboxUpdate = (suggestionInbox) => async (dispatch) => {  
  dispatch({
    type: SUGGESTION_INBOX_CHARGING,
  });

  try {
    dispatch({
      type: SUGGESTION_INBOX_UPDATE,
      payload: suggestionInbox,
    });
  } catch (error) {
    dispatch({
      type: SUGGESTION_INBOX_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};