import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SuggestionInboxService from "../../Services/SuggestionInboxService";
import TableCell from "@material-ui/core/TableCell";
import "../../Styles/UserDialog.scss";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CommentDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [commentDisplay, setCommentDisplay] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    setCommentDisplay(props.suggestionInbox.comment);
    setComment(props.suggestionInbox.comment);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    if(comment != commentDisplay) {
      setComment(commentDisplay);
    }
  };

  function handleTextFieldChange({ target: { _, value } }) {
    setComment(value)
  };

  async function onFormSubmit() {
    setLoading(true);

    let status;

    await SuggestionInboxService.updateSuggestionInbox({ id: props.suggestionInbox.id, comment: comment})
      .then((r) => (status = r));
    
    props.suggestionInboxUpdate(status.data)

    if (status.status === "ERROR") {
      toastr.error(
        t(
          "added-comment-message-error.label",
          "Error trying to comment"
        )
      );

      setLoading(false);
    } else {
      toastr.success(
        t(
          "added-comment.label",
          "Comment added"
        )
      );
      setCommentDisplay(comment);
      setLoading(false);
      handleClose();
    }
  }

  return (
    <>
      <TableCell
        onClick={handleClickOpen}
        align="left"
        className="pointer testTableCell"
      >
        {commentDisplay != null ? (
          <>
          {commentDisplay.length > 30
            ? commentDisplay.substring(0, 30) + "..."
            : commentDisplay}
          </>
        ) : (<></>)}
      </TableCell>
        
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("menu.badge-panel-dialog-delivery-comment", "Comment")}
        </DialogTitle>

        {loading && (
          <div className="div-spinner-modal">
            <DialogContent dividers>
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </DialogContent>
          </div>
        )}

        {!loading && (
          <>
            <DialogContent dividers>
              <TextField
                value={comment}
                name="comment"
                onChange={handleTextFieldChange}
                variant="outlined"
                className="mt-2"
                fullWidth
              />
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
              </Button>
              <Button onClick={onFormSubmit} color="primary">
                {t("admin.header-dropdown-dialog-actions-save", "Save")}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
